<template>
  <div>
    <div class="half-background"  v-if="$route.path != '/login' && !$route.path.includes('/saml2-login') &&  !$route.path.includes('/onboarding/') && !$route.path.includes('reset-password')"></div>
    <NavBar style='z-index: 1' v-if="$route.path != '/login' && !$route.path.includes('/saml2-login') &&  !$route.path.includes('/onboarding/') && !$route.path.includes('reset-password')" />
    <SaveCacheModal v-if="$route.path != '/login' && !$route.path.includes('/saml2-login') &&  $route.path != '/onboarding'  && !$route.path.includes('reset-password') &&  $store.state.checkedFirebase" @close="close" />
    <TimeRunnerModal v-if="$store.state.displayTimeRunnerModal" />
    <router-view></router-view>
       
    <NotificationPanel v-click-outside="() => hideNotifications()" v-if="$store.state.displayNotifications" />
    <AlertsPanel  v-click-outside="() => hideAlerts()" v-if="$route.path != '/login' && $store.state.displayAlerts" />
  </div>
</template>

<script>
import NavBar from './components/navigation/NavBar.vue';
import NotificationPanel from './components/notifications/NotificationPanel.vue';
import SaveCacheModal from '@/components/modals/SaveCacheModal.vue';
import TimeRunnerModal from '@/components/modals/TimeRunnerModal.vue';
import AlertsPanel from './components/notifications/AlertsPanel.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    NotificationPanel,
    SaveCacheModal,
    TimeRunnerModal,
    AlertsPanel
  },
  data(){
    return{
      displaySaveCacheModal: false,
      checkedCache: false,
      loggedIn: false,
      notificationNumber:0,
    }
  },
  created(){
    },
  methods:{
    hideAlerts() {
      this.$store.commit('setDisplayAlerts', false);
    },
    hideNotifications() {
      this.$store.commit('setDisplayNotifications', false);
    },
    close(value){
      if(value){
        console.log('app vue trying to close', this.displaySaveCacheModal);
        this.displaySaveCacheModal = false;
        this.$store.commit('setDisplayTimeRunnerModal', false);
        localStorage.removeItem('savedform');
        this.$store.commit('setCheckedFirebase', false);
      }
    }
  },
  mounted(){
    localStorage.removeItem('reg');
    this.$store.dispatch('getNotifications', null);
    this.$store.dispatch('getAlerts', null);

    let myHost = window.location.host;

    if(myHost.includes('hospital') && myHost.includes('staging')){
      this.$store.commit('setSubdomain', 'st-hp')
    } else if(myHost.includes('hospital')){
      this.$store.commit('setSubdomain', 'prod')
    } else{
      this.$store.commit('setSubdomain', 'st-hp')
    }
    // does not currently fetch new notifications after set time
  },
  computed:{
  }
  
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.half-background{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    z-index: -1;
    background-image: linear-gradient(to right, #0ddcf3 , #0973dd);
}
</style>


