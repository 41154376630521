<template>
    <div class="dashboard-container" style="display: flex; flex-direction: column; overflow: scroll; padding: 10px; ">
        <div style="display: flex; align-items: center;">
            <Dropdown @change="changeFacility()" placeholder="Select Facility" :options="facilities"
                v-model="selectedFacility" style="text-align: left; margin-left: 0px;" />
            <p class="outline-button" style="display: flex; padding: 5px 10px; margin-left: 10px;"
                @click="selectedFacility = '', filteredResults = []">Clear Search</p>
        </div>
        <!-- @click.stop="displayPatientSummary(item.uuid)" -->
        <router-link :to="'/patient/' + item.uuid"
            v-for="(item, index) in filteredResults.length ? filteredResults : patients" :key="index + 2" custom
            v-slot="{ navigate }">
            <div @click="navigate"
                style="background-color: #F3F4F4; padding: 0px; margin-bottom: 5px; border-radius: 8px; height: fit-content; text-decoration: none; color: black;">
                <div>
                    <div style="display: flex; flex-wrap: wrap; align-items: flex-end">
                        <div style="display: flex; flex-wrap: wrap; flex-direction: column; padding-bottom: 0px;">
                            <h4 style="padding-left: 10px;">{{ item.personal_information.first_name }} {{
                                item.personal_information.last_name }}</h4>
                            <div style="display: flex; flex-wrap: wrap; padding: 3px 0">
                                <div>
                                    <p class="field-title">Location</p>
                                    <p class="field-value" style="width: 15em; overflow-x: scroll">{{ item.facility ?
                                        item.facility : 'N/A' }}</p>
                                </div>
                                <div>
                                    <p class="field-title">Sex</p>
                                    <p class="field-value" style="width: 5em">{{ item.personal_information.sex }}</p>
                                </div>
                                <div>
                                    <p class="field-title">Date of birth</p>
                                    <p class="field-value" style="width: 7em">{{ item.personal_information.dob }}</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <p class="field-title">Last Review Date</p>
                            <div class="field-value-container" style="overflow: scroll; min-width: 10em">
                                <p class="field-value">{{ item.chart_reviews ? item.chart_reviews.reviewed_on : 'Not Reviewed' }}</p>
                                <p class="field-value">{{ item.chart_reviews ? 'Dr ' +
                                    item.chart_reviews.reviewed_by.personal_information.first_name + ' ' +
                                    item.chart_reviews.reviewed_by.personal_information.last_name : '' }}</p>
                            </div>
                        </div>
                    </div>

                    <div>
                        <p class="field-title">Alerts</p>
                        <p class="field-value">N/A</p>
                    </div>
                </div>
            </div>
        </router-link>

        <div v-if="loading">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem !important;margin-top: 2em;"></i>
        </div>
    </div>
</template>

<script>
// import PatientSummaryModal from '../modals/PatientSummaryModal.vue';

export default {
    props: ['patients', 'facilities', 'loading'],
    components: {
        // PatientSummaryModal
    },
    data() {
        return {
            expandPatientSummaryModal: false,
            patientId: '',
            selectedFacility: '',
            filteredResults: []
        }
    },
    methods: {
        displayPatientSummary(id) {
            console.log('DISPLAY IS BEING CLICKED ON');
            this.expandPatientSummaryModal = true;
            this.patientId = id;
        },
        close(value) {
            console.log('close is being clicked on');
            if (value) {
                this.expandPatientSummaryModal = false;
            }
        },
        changeFacility() {
            this.filteredResults = []
            this.patients.forEach(patient => {
                if (patient.facility == this.selectedFacility) {
                    this.filteredResults.push(patient);
                    console.log('its a match', this.selectedFacility)
                } else {
                    console.log('not a match', patient.facility, this.selectedFacility);
                }
            })
        }
    },
    mounted() {
        this.$emit('loadPrescriberPatients', true);
    }
}
</script>