<template>
  <transition name="component-fade" mode="out-in">
  <div class="menu sub-menu">
    <!-- $store.state.view == 'stock/stocktake' -->
    <router-link 
    @click="passViewToState('stock/requisitions')" v-if="$store.state.userType != 'prescriber' && $store.state.user.role.permissions.includes('can_view_transfers')"    
    class="menu-item sub-menu-item" id="requisition-item" v-bind:class="[ $route.name == 'Requisitions' ? 'active-sub-menu-item' : '']" to="/stock/requisitions">
      <p>Requisitions</p>
    </router-link>
    <router-link 
    @click="passViewToState('stock/stocktake')" v-if="$store.state.userType != 'prescriber' && $store.state.user.role.permissions.includes('can_view_stock_take')"    
    class="menu-item sub-menu-item" id="stock-take-item" v-bind:class="[ $route.name == 'Stocktake' ? 'active-sub-menu-item' : '']" to="/stock/stocktake">
      <p>Stocktake</p>
    </router-link>
    <router-link 
    @click="passViewToState('stock/transfers')" v-if="$store.state.userType != 'prescriber' && $store.state.user.role.permissions.includes('can_view_transfers')"   
    class="menu-item sub-menu-item" id="transfers-item" v-bind:class="[ $route.name == 'Transfers' ? 'active-sub-menu-item' : '']" to="/stock/transfers" >
      <p>Transfers</p>
    </router-link>
    <router-link 
    @click="passViewToState('stock/destructions')" v-if="$store.state.userType != 'prescriber' && $store.state.user.role.permissions.includes('can_view_destruction')"   
    class="menu-item sub-menu-item" id="destructions-item" v-bind:class="[$route.name == 'Destructions' ? 'active-sub-menu-item' : '']" to="/stock/destructions" >
      <p>Destructions</p>
    </router-link>
    <router-link 
    @click="passViewToState('stock/deliveries')" v-if="$store.state.userType != 'prescriber' && $store.state.user.role.permissions.includes('can_view_deliveries')"   
    class="menu-item sub-menu-item" id="deliveries-item" v-bind:class="[ $route.name == 'Deliveries' ? 'active-sub-menu-item' : '']" to="/stock/deliveries" >
      <p>Deliveries</p>
    </router-link>
    <!-- <router-link 
    @click="passViewToState('stock/outgoing')"    
    class="menu-item sub-menu-item" id="outgoing-item" v-bind:class="[$store.state.view == 'stock/outgoing' ? 'active-sub-menu-item' : '']" to="/stock/outgoing" >
      <p>Outgoing</p>
    </router-link> -->
    
  </div>
  </transition>
</template>

<script>
// requisitions, stock take, transfers, destructions, deliveries, outgoing
export default {
  name: 'AppMenu',
  data: function(){
    return{
      selectedView:''
    }
  },
  methods:{
    passViewToState(view){
      console.log('this is the current view subsub', view);
      this.$store.commit('changeView', view);
      this.selectedView = view;
    }
  },
  mounted(){
    this.selectedView = window.location.pathname.slice(1);
    this.$store.commit('changeView', this.selectedView);
    console.log('this is the value in stock submenu', this.selectedView);
  },
  computed(){
       console.log('this is the computed value from here in sub', this.selectedView);
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.active-sub-menu-item{
    background-color: white;
    border-radius: 12px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 0;
    p{
        padding: 8px;
        margin: 0 !important;
    }
}

.sub-menu-item:first-child{
  border-top-right-radius: 0 !important;
}

.sub-menu-item:last-child{
  border-top-left-radius: 0 !important;
}
</style>
