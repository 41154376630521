import { createRouter, createWebHistory } from 'vue-router'
import Theatre from '@/views/TheatreView.vue'
import Dashboard from '@/views/DashboardView.vue'
import DrugRegister from '@/views/DrugRegister.vue'
import Stock from '@/views/StockView.vue'
import Patients from '@/views/PatientsView.vue'
import Administration from '@/views/AdministrationView.vue'
import Reports from '@/views/ReportsView.vue'
import Settings from '@/views/AppSettings.vue'
import Login from '@/views/LoginView.vue'
import SAMLogin from '@/views/SAMLogin.vue'
import PasswordReset from '@/views/PasswordReset.vue'
import Onboarding from '@/views/OnboardingView.vue'
import PrescriberOnboarding from '@/views/PrescriberOnboarding.vue'
import CompanyOnboarding from '@/views/CompanyOnboarding.vue'
// Stock subroutes
import Deliveries from '@/views/stocksub/DeliveriesView.vue'
import Destructions from '@/views/stocksub/DestructionsView.vue'
// import Outgoing from '@/views/stocksub/Outgoing.vue'
import StockTake from '@/views/stocksub/StockTake.vue'
import Transfers from '@/views/stocksub/TransfersView.vue'
import Requisitions from '@/views/stocksub/RequisitionsView.vue'
// Patient
import PatientGeneral from '@/views/patient/PatientGeneral.vue'
// import PatientGeneralDeprecated from '@/views/patient/PatientGeneralDeprecated.vue'
import NewPatient from '@/views/patient/NewPatient.vue'
import EditPatient from '@/views/patient/EditPatient.vue'
import CreateChart from '@/views/patient/charts/CreateChart.vue'
import ActiveCharts from '@/views/patient/charts/ActiveCharts.vue'

// AUTH
// import auth from '@/middleware/auth';
// import log from '@/middleware/log';


const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            guest: true,
            title: 'Login - Strong Room'
        }
    },
    {
        path: '/saml2-login/:id',
        name: 'SAML Login',
        component: SAMLogin,
        meta: {
            guest: true,
            title: 'SAMLogin - Strong Room'
        }
    },
    {
        path: '/reset-password/:id',
        name: 'Password Reset',
        component: PasswordReset,
        meta: {
            guest: true,
            title: 'Password Reset - Strong Room'
        }
    },
    {
        path: '/onboarding/:id',
        name: 'Onboarding',
        component: Onboarding,
        meta: {
            guest: true,
            title: 'Onboarding - Strong Room'
        }
    },
    {
        path: '/onboarding/prescriber/:id',
        name: 'Prescriber Onboarding',
        component: PrescriberOnboarding,
        meta: {
            guest: true,
            title: 'Prescriber Onboarding - Strong Room'
        }
    },
    {
        path: '/onboarding/company/:id',
        name: 'Company Onboarding',
        component: CompanyOnboarding,
        meta: {
            guest: true,
            title: 'Company Onboarding - Strong Room'
        }
    },
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
        meta:{
            guest: false,
            requiresAuth: true,
            title: 'Dashboard - Strong Room'
        }
    },
    {
        path: '/drug-register',
        name: 'Drug Register',
        component: DrugRegister,
        meta:{
            guest: false,
            requiresAuth: true,
            title: 'Drug Register - Strong Room'
        }
        },
    {
        path: '/theatre',
        name: 'Theatre',
        component: Theatre,
        meta:{
            guest: false,
            requiresAuth: true,
            title: 'Theatre - Strong Room'
        }
        },
    {
        path: '/stock',
        name: 'Stock',
        component: Stock,
        meta:{
            requiresAuth: true,
            title: 'Stock - Strong Room'
        }
    },
    {
        path: '/patients',
        name: 'Patients',
        component: Patients,
        meta:{
            // requiresAuth: true,
            title: 'Patient Search - Strong Room'
        }
    },
    // {
    //     path: '/patient/:id/',
    //     name: 'Patient',
    //     component: PatientGeneralDeprecated,
    //     meta:{
    //         requiresAuth: true,
    //         title: 'Patient Information - Strong Room'
    //     }
    // },
    {
        path: '/patient/:id',
        name: 'Patient',
        component: PatientGeneral,
        meta:{
            // requiresAuth: true,
            title: 'Patient Information - Strong Room'
        }
    },
    {
        path: '/patient/:id/edit',
        name: 'Edit Patient',
        component: EditPatient,
        meta:{
            // requiresAuth: true,
            title: 'Edit Patient - Strong Room'
        }
    },
    {
        path: '/patient/:id/create-chart',
        name: 'Create New Chart',
        component: CreateChart,
        meta:{
            // requiresAuth: true,
            title: 'Create New Chart - Strong Room'
        }
        },
    {
        path: '/patient/:id/active-charts',
        name: 'Active Charts',
        component: ActiveCharts,
        meta:{
            // requiresAuth: true,
            title: 'Active Charts - Strong Room'
        }
        },
    {
        path: '/patient/new',
        name: 'New Patient',
        component: NewPatient,
        meta:{
            // requiresAuth: true,
            title: 'Create New Patient - Strong Room'
        }
        },
    {
        path: '/administration',
        name: 'Administration',
        component: Administration,
        meta:{
            // requiresAuth: true,
            title: 'Administration - Strong Room'
        }
        },
    {
        path: '/reports',
        name: 'Reports',
        component: Reports,
        meta:{
            // requiresAuth: true,
            title: 'Reports - Strong Room'
        }
        },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta:{
            requiresAuth: true,
            title: 'Settings - Strong Room'
        }
        },
    {
        path: '/stock/deliveries',
        name: 'Deliveries',
        component: Deliveries,
        meta:{
            requiresAuth: true,
            title: 'Deliveries - Strong Room'
        }
        },
    {
        path: '/stock/destructions',
        name: 'Destructions',
        component: Destructions,
        meta:{
            requiresAuth: true,
            title: 'Destructions - Strong Room'
        }
        },
    {
        path: '/stock/transfers',
        name: 'Transfers',
        component: Transfers,
        meta:{
            requiresAuth: true,
            title: 'Transfers - Strong Room'
        }
        },
    {
        path: '/stock/requisitions',
        name: 'Requisitions',
        component: Requisitions,
        meta:{
            // requiresAuth: true,
            title: 'Requisitions - Strong Room'
        }
        },
    {
        path: '/stock/stocktake',
        name: 'Stocktake',
        component: StockTake,
        meta:{
            requiresAuth: true,
            title: 'Stock Take - Strong Room'
        }, 
    }
]

const router = createRouter({ 
    history: createWebHistory(), 
    routes 
})


router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    localStorage.setItem('prev', JSON.stringify(from.path));
    console.log('this is the before each route being used', !('; '+document.cookie).split(`; auth=`).pop().split(';')[0]);
    if (to.name != 'login' && to.meta.requiresAuth && !('; '+document.cookie).split(`; auth=`).pop().split(';')[0])  next({ path: '/login' })
    else next()
    // console.log(to, from, next);
  })
  

// function checkAuth(to, from, next) 
// {
//     // if (to.name !== 'Login' && !to.meta.requiresAuth) next();
//     // else next("/login");
//     console.log(to, from, next)
// }
// function nextFactory(context, middleware, index) {
//     const subsequentMiddleware = middleware[index];
//     // If no subsequent Middleware exists,
//     // the default `next()` callback is returned.
//     if (!subsequentMiddleware) return context.next;
  
//     return (...parameters) => {
//       // Run the default Vue Router `next()` callback first.
//       context.next(...parameters);
//       // Then run the subsequent Middleware with a new
//       // `nextMiddleware()` callback.
//       const nextMiddleware = nextFactory(context, middleware, index + 1);
//       subsequentMiddleware({ ...context, next: nextMiddleware });
//     };
//   }
  
//   router.beforeEach((to, from, next) => {
//     if (to.meta.middleware) {
//       const middleware = Array.isArray(to.meta.middleware)
//         ? to.meta.middleware
//         : [to.meta.middleware];
  
//       const context = {
//         from,
//         next,
//         router,
//         to,
//       };
//       const nextMiddleware = nextFactory(context, middleware, 1);
  
//       return middleware[0]({ ...context, next: nextMiddleware });
//     }
  
//     return next();
//   });
export default router