<template>
    <div class="modal-mask">
        <div class="modal-wrapper">
            <div class="modal-container" style="width: 20%; min-width: 500px">
                <div class="modal-header">
                    <slot name="header">
                        <h3 class="form-header">Password Reset</h3>
                    </slot>
                </div>
                <div class="modal-body">
                    <slot name="body">
                        <form class="action-modal">

                            <!-- BASIC INFO -->
                            <!-- BASIC INFO -->
                            <div class="form-section-container" style="grid-template-columns: 1fr; padding: 0; padding-bottom: 20px;">
                                <div>

                                    <div style="display: grid; padding: 0 20px;">
                                        <p class="note-header" style="padding: 10px; font-size: 1em;">Since this is your first time logging in, change your password here.</p>
                                        <h4 style="text-align: left;">
                                            Reset Password
                                            </h4>
                                            <div style="display: grid; grid-gap: 10px;">
                                                <label style="text-align: left" for="old_password">
                                                Old Password <span style="color: red;">*</span>
                                                </label>
                                                <Password v-model="passwordChange.old_password" toggleMask :feedback="false" inputStyle="width:100%;"></Password>
                                                <label style="text-align: left" for="new_password">
                                                New Password <span style="color: red;">*</span>
                                                </label>
                                                <Password v-model="passwordChange.password" toggleMask :feedback="false" inputStyle="width:100%;"></Password>
                                                <label style="text-align: left" for="confirm_new_password">
                                                Confirm New Password <span style="color: red;">*</span>
                                                </label>
                                                <Password v-model="passwordChange.password_confirm" toggleMask :feedback="false" inputStyle="width:100%;"></Password>
                                            </div>
                                            

                                            <ul>
                                            <template v-for="(value, name) in passwordRestrictions" :key="name">
                                                <li v-if="name == 'min_pwd_length' && value !== null && value !== false" style="text-align: left">
                                                At least {{ value }} characters
                                                </li>
                                                <li v-else-if="name == 'at_least_1_num_pwd' && value !== null && value !== false" style="text-align: left">
                                                At least 1 number
                                                </li>
                                                <li v-else-if="name == 'at_least_1_special_char_pwd' && value !== null && value !== false" style="text-align: left">
                                                At least 1 special character
                                                </li>
                                                <li v-else-if="name == 'pwd_has_letters' && value !== null && value !== false" style="text-align: left">
                                                At least 1 letter
                                                </li>
                                                <li v-else-if="name == 'pwd_is_mixed_case' && value !== null && value !== false" style="text-align: left">
                                                At least 1 uppercase and 1 lowercase letter
                                                </li>
                                                <li v-else-if="name == 'pwd_reuse_interval' && value !== null && value !== false" style="text-align: left">
                                                Password Reuse Interval: {{ value }} days
                                                </li>
                                                <li v-else-if="value !== null && value !== false" style="text-align: left">
                                                {{ name }}: {{ value }}
                                                </li>
                                            </template>
                                            </ul>

                                            <!-- <p style= "color:red;" v-if="passwordChange.password != passwordChange.password_confirm">
                                            Passwords do not match.
                                            </p> -->
                                            <p style= "color:red;" v-if="error_message != ''">
                                            {{ error_message }}
                                            </p>
                                            <div style="display: flex;">
                                            <span class="green-button" style="width: fit-content; margin-right: 10px;"
                                                v-if="passwordChange.old_password && passwordChange.password && passwordChange.password_confirm"
                                                @click="editPersonalPassword()">Reset</span>
                                            <!-- <span class="black-button" style="width: fit-content;"
                                                @click="resetPassword = false, passwordChange.old_password = '', passwordChange.password ='', passwordChange.password_confirm = ''">Cancel</span> -->
                                            </div>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import DualVerify from '@/components/verification/DualVerify.vue';
import axios from 'axios';

export default {
    props: [],
    components: {
        // DualVerify
    },
    data() {
        return {
            error_message: '',
            displaySignatureModal: false,
            form: {
                submit: {
                    notes: '',
                    uuid: ''
                }
            },
            resetPassword: false,
            editLoginPassword: true,
            reference_number: '',
            date_received: '',
            passwordRestrictions:null,
            passwordChange:{
                old_password:'',
                password:'',
                password_confirm:''
            },
        }
    },
    methods: {
        closeModal() {
            console.log('hitting this closemodal function')
            this.$emit('close', true);
        },
        validateForm(password, password_confirm){
            if (password != password_confirm &&
                    (password_confirm != '' && password != '')) {
                    this.error_message = "Passwords do not match.";
                    return false;
            }else{
                this.error_message = "";
                return true;
            }
        },
        editPersonalPassword() {

            if(!this.validateForm(this.passwordChange.password, this.passwordChange.password_confirm)){
                return false;
            }

            this.error_message = '';
            this.displaySuccessModal = true;
            this.successMessage = 'Updating password...'
            axios.post('/reset-password/account', this.passwordChange, this.$store.state.header).then(res => {
                console.log('login has been edited', res);
                localStorage.setItem('reset_password', false);
                this.editLoginPassword = false;
                this.resetPassword = false;
                this.successMessage = 'Password updated!'
                this.passwordChange = {
                    old_password: '',
                    password:'',
                    password_confirm: ''
                }
                this.closeModal()
            }).catch(err => {
                console.log('err issss', typeof err.response.data);
                this.successMessage = err.response.data.errors ? (err.response.data.errors && err.response.data.errors.password ? err.response.data.errors.password[0] : err.response.data.message) : err.response.data;
                this.error_message = err.response.data.errors ? (err.response.data.errors && err.response.data.errors.password ? err.response.data.errors.password[0] : err.response.data.message) : err.response.data;
                try {
                    if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                    }   
                } catch (error) {
                    console.log('err is', err.response.data);
                }
                
            })
        },
        getPasswordRestrictions() {
            axios.post('/get-password-restrictions', {}, this.$store.state.header).then(res => {
                console.log("this is the data: " + res.data);
                this.passwordRestrictions = res.data;
            }).catch(err => {
                this.errorMessage = err.response.data;
                console.log('this is the err', err, err.response.data);
                if (err.response && err.response.data && err.response.data.split(' ').includes('expired')) {
                    console.log('yes, it does include expired')
                    this.$router.push('/login');
                }
            })
        }
    },
    mounted() {
        console.log('this is the display from action modal', this.display);
        this.getPasswordRestrictions()
    },
    computed: {
    },
    watch: {
        passwordChange: {
            handler(newValue) {
                this.validateForm(newValue.password, newValue.password_confirm);
            },
            deep: true
        }
    }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.section-container {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-gap: 10px;
}
</style>
